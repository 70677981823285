
import React, { useEffect } from 'react';
import Credentials from "../services/Credentials";
import CreateDelivery from "../services/CreateDelivery";
import GetDelivery from "../services/GetDelivery";
import ConfirmDelivery from "../services/ConfirmDelivery";
import CancelDelivery from "../services/CancelDelivery";
import DefaultDelivery from "../services/DefaultDelivery";
import StatusList from "../services/StatusList";
import ErrorsList from "../services/ErrorsList";

export default function Home() {

useEffect(() => {

	const script = document.createElement('script');
	script.src = "./assets/js/script.js";
	script.async = true;
	document.body.appendChild(script);

	return function cleanup() {
		document.body.removeChild(script);
	};
});

  return (
    <React.Fragment>
      <div className="left-menu">
        <div className="content-logo">
          <div className="logo">
            <img
              src="assets/images/logo.png"
              height="32"
            />
            <span>API TastyDelivery</span>
          </div>
          <button className="burger-menu-icon" id="button-menu-mobile">
            <svg width="34" height="34" viewBox="0 0 100 100">
              <path
                className="line line1"
                d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
              ></path>
              <path className="line line2" d="M 20,50 H 80"></path>
              <path
                className="line line3"
                d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
              ></path>
            </svg>
          </button>
        </div>
        <div className="mobile-menu-closer"></div>
        <div className="content-menu">
          <div className="content-infos">
            <div className="info">
              <b>Version:</b> 1.0
            </div>
            <div className="info">
              <b>Last Updated:</b> 15 Apr, 2022
            </div>
          </div>
          <ul>
            <li className="scroll-to-link active" data-target="content-get-started">
              <a>GET STARTED</a>
            </li>
            <li className="scroll-to-link" data-target="content-authentication">
              <a>Authentication</a>
            </li>
            <li className="scroll-to-link" data-target="content-credentials">
              <a><span className="method method-get">GET</span>Credenciales del Servicio</a>
            </li>
            <li className="scroll-to-link" data-target="content-create-delivery">
              <a><span className="method method-post">POST</span>Crear Delivery</a>
            </li>
            <li className="scroll-to-link" data-target="content-get-delivery">
              <a><span className="method method-post">POST</span>Consultar Delivery</a>
            </li>
            <li className="scroll-to-link" data-target="content-confirmar-delivery">
              <a><span className="method method-post">POST</span>Confirmar Delivery</a>
            </li>
            <li className="scroll-to-link" data-target="content-cancel-delivery">
              <a><span className="method method-post">POST</span>Cancelar Delivery</a>
            </li>
            <li className="scroll-to-link" data-target="content-default-delivery">
              <a><span className="method method-post">POST</span>Default Delivery</a>
            </li>
            <li className="scroll-to-link" data-target="content-status-list">
              <a>Estatus</a>
            </li>
            <li className="scroll-to-link" data-target="content-errors-list">
              <a>Errores</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="content-page">
        <div className="content-code"></div>
        <div className="content">
          <div className="overflow-hidden content-section" id="content-get-started">
            <h1>Get started</h1>
            <pre>
            	<p style={{ color:'#fff', marginBottom:'0'}}>API Endpoints:</p>
            	<p style={{ color:'#fff', marginBottom:'0'}}>-For Canada https://tastydelivery.ca/</p>
            	<p style={{ color:'#fff', marginBottom:'0'}}>-For Chile https://tastydeliverycl.com/</p>
            </pre>
            <p>
				La API TastyDelivery es un servicio mediante el cual se solicitan riders 
				para la entrega de las ordenes de TastyBoom a través de plataformas externas 
				de delivery asociadas a Tastyboom como Pedidos Ya / Doordash / etc.
            </p>
            <p>
            	Para utilizar esta API es necesario ponerse en contacto con los administradores del sistema 
            	para obtener el <strong>token</strong> que le permitirá tener acceso a las funcionalidades.
            </p>
          </div>
          <div className="overflow-hidden content-section" id="content-authentication">
            <h1>OAuth</h1>
            <pre> 'authorization: Bearer {`<token>`}' </pre>
            <p>
              Todos los endpoints requieren una cabecera de autorización con un valor 
              de token, que debe ser como el siguiente ejemplo 
              <br/><br/>'authorization: Bearer eyJ0eXAiOiJKV1QiLCJey'.
            </p>
          </div>
          <div
            className="overflow-hidden content-section"
            id="content-credentials"
          >
          	<Credentials/>
          </div>
          <div
            className="overflow-hidden content-section"
            id="content-create-delivery"
          >
          	<CreateDelivery/>
          </div>
          <div
            className="overflow-hidden content-section"
            id="content-get-delivery"
          >
          	<GetDelivery/>
          </div>
          <div
            className="overflow-hidden content-section"
            id="content-confirmar-delivery"
          >
          	<ConfirmDelivery/>
          </div>
          <div
            className="overflow-hidden content-section"
            id="content-cancel-delivery"
          >
          	<CancelDelivery/>
          </div>
          <div
            className="overflow-hidden content-section"
            id="content-default-delivery"
          >
          	<DefaultDelivery/>
          </div>
          <div
            className="overflow-hidden content-section"
            id="content-status-list"
          >
          	<StatusList/>
          </div>
          <div
            className="overflow-hidden content-section"
            id="content-errors-list"
          >
          	<ErrorsList/>
          </div>
        </div>
        <div className="content-code"></div>
      </div>
    </React.Fragment>
  );
}
