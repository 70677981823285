
import React from 'react';

export default function ErrorsList() {

  return (
    <React.Fragment>
            <h2>Errores</h2>
            <p>
              La API de tastyDelivery utiliza los siguientes códigos de error de acuerdo a 
              los valores manejados en cada plataforma externa de delivery asociada a TastyBoom, para estatus 500 / 400 / 403.
              Los valores de error son:<br />
            </p>
            <br />
            <table className="central-overflow-x">
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ERROR_API</td>
                  <td>Error API</td>
                </tr>
                <tr>
                  <td>INVALID_DELIVERY_TIME</td>
                  <td>The delivery time is outside fleet schedule.</td>
                </tr>
                <tr>
                  <td>WAYPOINTS_OUT_OF_ZONE</td>
                  <td>Order is out of zone.</td>
                </tr>
                <tr>
                  <td>WAYPOINTS_NOT_FOUND</td>
                  <td>We were not able to find lat/long for waypoint/s in order.</td>
                </tr>
                <tr>
                  <td>MAX_DISTANCE_EXCEEDED</td>
                  <td>The max distance is exceeded.</td>
                </tr>
                <tr>
                  <td>INVALID_CATEGORY</td>
                  <td>The categoryId does not exists.</td>
                </tr>
                <tr>
                  <td>TEMPORARILY_CLOSED</td>
                  <td>The fleet service is temporarily closed.</td>
                </tr>
                <tr>
                  <td>DELAY_IN_ZONE_FOR_DELIVERY_TIME</td>
                  <td>There is a fleet delay in the working zone for the desired delivery time.</td>
                </tr>
                <tr>
                  <td>MAX_VALUE_EXCEEDED</td>
                  <td>The sum of the item\'s declared price (value field) is greater than the allowed max price.</td>
                </tr>
                <tr>
                  <td>MAX_WAYPOINTS_TYPE_EXCEEDED</td>
                  <td>Only one waypoint can exist with type pick-up.</td>
                </tr>
                <tr>
                  <td>MAX_VOLUME_EXCEEDED</td>
                  <td>The volume limit was exceeded.</td>
                </tr>
                <tr>
                  <td>MAX_WEIGHT_EXCEEDED</td>
                  <td>The weight limit was exceeded.</td>
                </tr>
                <tr>
                  <td>MAX_ITEM_QUANTITY_EXCEEDED</td>
                  <td>The item quantity limit was exceeded.</td>
                </tr>
                <tr>
                  <td>JSON_INVALID</td>
                  <td>Invalid json.</td>
                </tr>
                <tr>
                  <td>INVALID_PARAM</td>
                  <td>Invalid parameter in path request.</td>
                </tr>
                <tr>
                  <td>MAX_ITEMS_EXCEEDED</td>
                  <td>The number of items allowed was exceeded.</td>
                </tr>
                <tr>
                  <td>NO_SERVICE_AVAILABLE</td>
                  <td>No service available.</td>
                </tr>
              </tbody>
            </table>
    </React.Fragment>
  );
}
