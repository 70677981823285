
import React from 'react';

export default function CancelDelivery() {

  return (
    <React.Fragment>
            <h2>Confirmar Delivery</h2>
            <pre>
              <code className="bash">
                <span className="method method-post">POST</span> /api//{`{code_service}`}/shipping/{`{id_delivery}`}/cancel
              </code>
            </pre>
            <p>
              Cancela la solicitud de un rider en el servicio externo de delivery seleccionado. No alicable para solicitudes 
              de la plataforma Evaa.
            </p>
            <p>
              Para hacer uso de esta funcionalidad debe realizar una llamada POST enviando en la url el codigo 
              que identifica al servicio externo de delivery en TastyBoom, y el identificador de la solicitud 
              de delivery.<br />
            </p>
            <br />
            <pre>
Request example :
<p className="p-content-code">
	<h5>Content type: application/json</h5>
    <code className="json">
{`
  {
    oid: 45896,
  }
`}
	</code>
</p>
            </pre>
            <h4>QUERY PARAMETERS</h4>
            <table className="central-overflow-x">
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Type</th>
                  <th>Required</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>code_service</td>
                  <td>String</td>
                  <td>Yes</td>
                  <td>Codigo que identifica al servicio externo de delivery en TastyBoom.
                  <br/>Examples: evaa</td>
                </tr>
                <tr>
                  <td>id_delivery</td>
                  <td>Integer</td>
                  <td>Yes</td>
                  <td>Identificador de la solicitud de delivery en el servicio externo.
                  <br/>Examples: evaa</td>
                </tr>
                <tr>
                  <td>oid</td>
                  <td>Integer</td>
                  <td>Yes</td>
                  <td>Order number in TastyBoom.
                  <br/>Examples: 45896</td>
                </tr>
              </tbody>
            </table>
            <br />
            <pre>
Response example :
<p className="p-content-code">
	<h5>Content type: application/json</h5>
    <code className="json">
{`
  {
    data:{
      id: 14462107071653285148622,
      confirmationCode: 377382970,
      deliveryTime: "2021-07-07 18:09:24",
      shareLocationUrl: "https://envios.pedidosya.cl/tracking/MTQ0NjIxMDcwNzE2NTMyODUxNDg2MjIjQVBJIzE0NDY=",
      status: "CANCELLED_USER_CANCELLED",
    },
    code_service: "pedidosya",
    atk: "CAd28d6d5f0d374a9da35c4da9",
  }
`}
	</code>
</p>
            </pre>
            <h4>RESPONSES</h4>
            <table className="central-overflow-x">
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>data</td>
                  <td>Objeto con la data de la solicitud creada retornada por la plataforma de delivery externa.</td>
                </tr>
                <tr>
                  <td>data.id</td>
                  <td>Identificador de la solicitud de delivery.</td>
                </tr>
                <tr>
                  <td>data.confirmationCode</td>
                  <td>Codigo de confirmacion de la solicitud de delivery. En caso de ser una solicitud del tipo PREORDER, este codigo es null.</td>
                </tr>
                <tr>
                  <td>data.deliveryTime</td>
                  <td>Fecha/hora para cual fue programado el delivery en la plataforma externa.</td>
                </tr>
                <tr>
                  <td>data.shareLocationUrl</td>
                  <td>Link de acceso al mapa en el cual se visualiza el delivery. Para algunas plataformas externas este valor es null.</td>
                </tr>
                <tr>
                  <td>data.status</td>
                  <td>Estatus con el cual fue creada la solicitud de delivery.</td>
                </tr>
                <tr>
                  <td>code_service</td>
                  <td>Codigo que identifica al servicio externo de delivery en TastyBoom.</td>
                </tr>
                <tr>
                  <td>atk</td>
                  <td>Token con el cual se hizo la ultima solicitud al servicio externo. Para algunas plataformas externas este valor es null.</td>
                </tr>
              </tbody>
            </table>
    </React.Fragment>
  );
}
